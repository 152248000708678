export const uploadModule = {
    application: 'application',
    agent: 'agent',
    university: 'university',
    courses: 'courses',
    profile: 'profile',
    users: 'users',
    application_statuses: 'application statuses',
    master_tool: 'master tool',
    university_contract: 'university contract'
}

export const permissionEntity = { // don't change the spellings of below parameters
    modules: {
        dashboard: 'dashboard',
        agents: 'agents',
        counsellors: 'counsellors',
        applications: 'applications',
        university_cat_cpt_art:'university cat/cpt/art',
        university: 'university',
        universities_entry_requirement: 'universities entry requirement',
        university_affiliate: 'university affiliate',
        universities_process_link: 'universities process link',
        universities_filter_master: 'universities filter master',
        university_filter_pg: 'university filter pg',
        university_filter_ug: 'university filter ug',
        courses: 'courses',
        options: 'options',
        filters: 'filters',
        art_received: 'art received',
        form_master_form: 'form',
        cat_recieved: 'cat received',
        cpt_assign_application: 'cpt assign application',
        cpt_document_verification: 'cpt document verification',
        cpt_processed_application: 'cpt processed application',
        cct_interview_master: 'cct interview master',
        cct_interview_request: 'cct interview request',
        cct_quality_master: 'cct quality master',
        cct_view_interview: 'cct view interview',
        cct_assign_interview: 'cct assign interview',
        admin_notice_board: 'admin notice board',
        agent_notice_board: 'agent notice board',
        agent_webinar_board: 'agent webinar board',
        cabin: 'cabin',
        company: 'company',
        employee_designation: 'employee designation',
        job_role: 'job role',
        country: 'country',
        state: 'state',
        regions: 'regions',
        continents: 'continents',
        document_restrictions: 'document restrictions',
        smtp: 'smtp',
        email_template: 'email template',
        email_events: 'email events',
        universities_email: 'universities e-mail',
        university_communication: 'university communication',
        mail_box: 'mail box',
        mmr_team: 'mmr team',
        store: 'store',
        sub_store: 'sub store',
        board: 'board',
        marksheet_verfication: 'marksheet verfication',
        student_previous_qualification: 'student previous qualification',
        application_primary_status: 'application primary status',
        application_auto_changes: 'application auto changes',
        application_secondary_status: 'application secondary status',
        application_communication_status: 'application communication status',
        application_internal_status: 'application internal status',
        link_builder: 'link builder',
        users: 'users',
        user_groups: 'user groups',
        agent_commission: 'agent commission',
        university_contract: 'university contract',
        profile: 'profile',
        update_password: 'update password',
        interview_type:'interview type',
        interview_mode:'interview mode'
    },
    access: {
        read: 'read',
        read_all: 'read all',
        create: 'create',
        edit: 'edit',
        delete: 'delete',
        filters: 'filters',
        uploads: 'uploads',
        downloads: 'downloads'
    }
}

