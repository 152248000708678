<mat-card
    class="mb-25 tagus-card"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <a
        mat-flat-button
        class="tagus fw-semibold d-block composebtn"
        href="javascript:void(0)"
        (click)="composeMail()"
        *ngIf="(permissions_entity.modules.mail_box | permissions : permissions_entity.access.create)"
        >Compose</a>
    </mat-card-header>
    <mat-card-content class="card-content">
        <mat-accordion [togglePosition]="'before'">
            <ng-container *ngFor="let sidebar of domain_sidebar; index as i">
                <mat-expansion-panel 
                    (opened)="panelOpenState = true;" 
                    (closed)="panelOpenState = true" 
                    [expanded]="selected_domain_index === i">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="title">
                                <!-- {{sidebar?.domain}} - -->
                                <span [matTooltip]="sidebar?.mail?.length > 30 ? sidebar?.mail : ''">
                                    {{sidebar?.mail | slice:0:30}}
                                    <span *ngIf="sidebar?.mail?.length > 30">...</span>
                                </span>
                            </span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul class="email-sidebar ps-0 list-unstyled mb-0">
                        <ng-container *ngFor="let menus of sidebar?.sub_menus">
                            <li class="position-relative">
                                <i class="{{menus?.icon}}"></i>
                                <a
                                    href="javascript:void(0)"
                                    (click)="loadMail(menus, sidebar)"
                                    class="fw-semibold position-relative d-block"
                                    routerLinkActive="active"
                                    [ngClass]="{'gray-color': menus.id === selected_menu.id && selected_domain?.mail === sidebar.mail}"
                                >
                                    {{menus.menu}}
                                    <span class="d-block">{{menus.count > 0 ? menus.count : ''}}</span>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                    <span class="sub-title d-flex muted-color fw-normal fs-14 justify-content-space-between ">
                        FOLDERS
                        <i class="ri-add-circle-line pt-2 fs-16 main-color cursor-pointer" matTooltip="Create folder" (click)="createFolder(sidebar.domain)"></i>
                    </span>
                    <ul class="email-sidebar ps-0 list-unstyled mb-0 folder-menus">
                        <li class="position-relative" *ngIf="show_folder_input && sidebar.domain === selected_folder_domain">
                            <ng-container>
                                <input type="text" class="form-control folder-input" [(ngModel)]="folder_name"/>
                                <button mat-button 
                                    color="primary" 
                                    class="save-fold-btn"
                                    (click)="submitFolder(sidebar.domain)"
                                    [disabled]="!folder_name">
                                    {{folder_action_type === 'add' ? 'Save' : 'Update'}}
                                </button>
                                <button mat-button 
                                    color="warn" 
                                    class="save-fold-btn"
                                    (click)="show_folder_input = false; folder_name = ''; selected_folder_domain = ''">
                                    Cancel
                                </button>
                            </ng-container>
                        </li>
                        <ng-container *ngIf="sidebar && sidebar?.totalFolderCount?.length">
                            <li class="position-relative" *ngFor="let folders of sidebar?.totalFolderCount; index as j">
                                <i class="ri-folder-line"></i>
                                <a
                                    href="javascript:void(0)"
                                    class="fw-semibold position-relative d-block"
                                    routerLinkActive="active"
                                    [ngClass]="{'gray-color': folders.id === selected_menu.id && selected_domain?.mail === sidebar.mail}"
                                    (click)="loadMail(folders, sidebar); $event.stopPropagation()"
                                >
                                    {{folders?.name}}
                                    <span class="d-block folder-count">{{folders.count > 0 ? folders.count : ''}}</span>
            
                                    <span class="d-block edit-icon" (click)="editFolder(j, sidebar.domain, folders); $event.stopPropagation()">
                                        <i class="ri-pencil-line"></i>
                                    </span>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </mat-expansion-panel>
            </ng-container>
        </mat-accordion>
    </mat-card-content>
</mat-card>