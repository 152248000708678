import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { MailCommon } from 'src/app/core/constants/mail-box-const';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { ToasterService } from 'src/app/core/services/toaster/toaster.service';
import { UniversityCommunicationService } from 'src/app/core/services/university-communication/university-communication.service';

@Component({
  selector: 'app-email-warning-popup',
  templateUrl: './email-warning-popup.component.html',
  styleUrls: ['./email-warning-popup.component.scss']
})
export class EmailWarningPopupComponent implements OnInit {
  mail_common = MailCommon;
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<EmailWarningPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _universitycommunicationService: UniversityCommunicationService,
    private _toasterService: ToasterService,
    private _loaderService: LoaderService,
  ) {}

  ngOnInit(): void {
  }

  close(val: string) {
    this.dialogRef.close(val);
  }

  saveDraft(eve) {
    this._universitycommunicationService.saveDraft(eve?.params, eve?.formData)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((res) => {
      if (res) {
        this._toasterService.showSuccess('SUCCESS', 'Email Drafted');
        this.close(this.mail_common?.discard);
        this._loaderService.hideLoader();
      }
    }, (err) => {
      this._loaderService.hideLoader();
    })
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }
}
