export const MailSidebar = [
    { id: 0, menu: 'Inbox', icon: 'ri-mail-line', count_label: 'totalInbox', count: 0 },
    { id: 2, menu: 'Sent', icon: 'ri-mail-send-line', count_label: 'totalSent', count: 0 },
    { id: 8, menu: 'Draft', icon: 'ri-draft-line', count_label: 'totalDrafts', count: 0 },
    { id: 4, menu: 'Important', icon: 'ri-error-warning-line', count_label: 'totalImportant', count: 0 },
    { id: 1, menu: 'Archived', icon: 'ri-archive-line', count_label: 'totalArchive', count: 0 },
    { id: 5, menu: 'Shared with me', icon: 'ri-share-line', count_label: 'totalShared', count: 0 },
    { id: 6, menu: 'Junk', icon: 'ri-mail-forbid-line', count_label: 'totalJunk', count: 0 },
]

// export const MailTypes = {
//     inbox: {type: 'Inbox', id: 0},
//     sent: {type: 'Sent', id: 2},
//     important: {type: 'Important', id: 4},
//     archived: {type: 'Archived', id: 1},
//     share: {type: 'Share', id: 5},
// }

export const MailTypes = [
    { id: 4, type: 'Important', msg: 'Important' },
    { id: 1, type: 'Archived', msg: 'Archived' },
    { id: 5, type: 'Share', msg: 'Shared' },
    { id: 6, type: 'Junk', msg: 'Junk' },
    { id: 8, type: 'Draft', msg: 'Draft' },
]

export const MailTypeIds = {
    inbox: 0,
    archived: 1,
    sent: 2,
    important: 4,
    share: 5,
    junk: 6,
    draft: 8,
}

export const MailCommon = {
    selected_domain: 'selected_domain_mails',
    discard: 'discard',
    continue: 'continue',
    missing_subject: 'missing_subject',
    send: 'send',
    dont_send: 'dont_send',
    large_file: 'large_file'
}