import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentFormat'
})
export class DocumentFormatPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    value = value ? value.split('/').pop() : '-';
    return value;
  }

}
