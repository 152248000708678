<div class="create-dialog-box scrollable-dialog">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Share</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close(false)"><i
                class="flaticon-close"></i></button>
    </div>

    <mat-card class="tagus-card add-user-wrapper">
        <mat-card-content>
            <form [formGroup]="share_user_form" (ngSubmit)="share()">
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label class="form-label">
                                Select Users
                            </mat-label>
                            <mat-select class="form-control" 
                                formControlName="user_id" 
                                msInfiniteScroll
                                (infiniteScroll)="getNextURM()"
                                [complete]="user_offset === total_user_count"
                                multiple>
                                <mat-option>
                                    <ngx-mat-select-search 
                                        [formControl]="user_filter_control"
                                        placeholderLabel="Find name..." 
                                        noEntriesFoundLabel="'No matching name found'"
                                        [clearSearchInput]="search_entiry.clear_search_input">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <ng-container *ngIf="find_extisting_users?.length">
                                    <mat-option *ngFor="let exist_user of find_extisting_users" [value]="exist_user?.id">
                                        {{exist_user?.name}} <span *ngIf="exist_user?.role">({{exist_user?.role}})</span>
                                    </mat-option>
                                </ng-container>
                                <mat-option 
                                    *ngFor="let user of user_list"
                                    [value]="user?.id">
                                        {{user?.name}} <span *ngIf="user?.role">({{user?.role}})</span> 
                            </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="text-end">
                        <button type="button" class="gray" (click)="close(false)">
                            <i class="ri-close-fill"></i> Cancel
                        </button>
                        <button type="submit">
                            <i class="ri-share-line"></i> Share
                        </button>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>