export const universityCommunicationApi = {
    // getIncomingMail: "v1/api/incoming-email/mail",
    getIncomingMail: "v1/api/userMailMaster/incoming-email/mail",
    // getMailDetailsById: "v1/api/incoming-email/mailDetails",
    getMailDetailsById: "v1/api/userMailMaster/incoming-email/mailDetails",
    // sendMail: 'v1/api/incoming-email/sendmail',
    sendMail: 'v1/api/userMailMaster/incoming-email/sendmail',
    bulkUpdate: 'v1/api/userMailMaster/incoming-email/updateMail?',
    assignMail: 'v1/api/incoming-email?',
    getMailsByAppId: 'v1/api/mailCommunication?applicationId=',
    updateCommunicationMail: 'v1/api/mailCommunication?mailCommunicationId=',
    // getDashboardCounts: 'v1/api/incoming-email/dashboard',
    getDashboardCounts: 'v1/api/userMailMaster/incoming-email/dashboard',
    // downloadMail: 'v1/api/incoming-email/downloadEmail?messageId=',
    downloadMail: 'v1/api/userMailMaster/incoming-email/downloadEmail?messageId=',
    uploadMail: 'v1/api/incoming-email/pdfAndUpload',
    updateRead: 'v1/api/userMailMaster/incoming-email/readMail?Id=',
    mailRecieveWebhook: 'v1/api/userMailMaster/incoming-email/receive-webhook',
    createFolder: 'v1/api/users/update?Id=',
    saveDrafts: 'v1/api/userMailMaster/incoming-email/saveDrafts',
    OneSignalSubscribe: 'v1/api/auth/subscribeOnsignal?device_type=:deviceType&userId=:userId&playerId=:playerId'
}