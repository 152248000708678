import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { SearchEntity } from 'src/app/core/constants/dropdown-contants';

@Component({
  selector: 'app-search-mail',
  templateUrl: './search-mail.component.html',
  styleUrls: ['./search-mail.component.scss']
})
export class SearchMailComponent implements OnInit, OnDestroy {
  @Output() trigger_search: EventEmitter<any> = new EventEmitter();
  txtQuery: string; // bind this to input with ngModel
  txtQueryChanged: Subject<string> = new Subject<string>();
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor() { }

  ngOnInit(): void {
    this.txtQueryChanged.pipe(
      debounceTime(SearchEntity.debounce_time),
      distinctUntilChanged(),
      takeUntil(this._unsubscribeAll)
    ).subscribe(res => {
      this.txtQuery = res;
      this.trigger_search.emit(this.txtQuery);
    });
  }

  onFieldChange(query: string) {
    this.txtQueryChanged.next(query);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
