import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class CustomValidatorService {
    constructor() {}

    patternValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
                return null as any;
            }
            const uppercaseRegex = new RegExp('^(?=.*?[A-Z])');
            const lowercaseRegex = new RegExp('^(?=.*?[a-z])');
            const numRegex = new RegExp('^(?=.*?[0-9])');
            const validNum = numRegex.test(control.value);
            const validUppercase = uppercaseRegex.test(control.value);
            const validLowercase = lowercaseRegex.test(control.value);
            return !validNum
                ? { validNum: true }
                : !validUppercase
                ? { validUppercase: true }
                : !validLowercase
                ? { validLowercase: true }
                : (null as any);
        };
    }
}
