import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { ImageUploadComponent } from 'src/app/views/components/image-upload/image-upload.component';
import { NumericInputDirective } from '../helpers/phoneDirective';
import { NoDataFoundComponent } from 'src/app/views/components/no-data-found/no-data-found.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { QuillModule } from 'ngx-quill';
import { SpecialCharPipe } from '../pipe/special-char.pipe';
import { DocumentFormatPipe } from '../pipe/document-format.pipe';
import { PermissionsPipe } from '../pipe/permissions.pipe';
import { SafehtmlPipe } from '../pipe/safehtml.pipe';
import { SearchMailComponent } from './components/search-mail/search-mail.component';
import { UniversityCommunicationService } from '../services/university-communication/university-communication.service';
import { TagInputModule } from 'ngx-chips';
import { MailSidebarComponent } from './components/mail-sidebar/mail-sidebar.component';
import { EmailActivityComponent } from './components/email-activity/email-activity.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CdkAccordionModule } from '@angular/cdk/accordion';

@NgModule({
    declarations: [
        ImageUploadComponent,
        NumericInputDirective,
        NoDataFoundComponent,
        DocumentFormatPipe,
        PermissionsPipe,
        SafehtmlPipe,
        SearchMailComponent,
        MailSidebarComponent,
        EmailActivityComponent
    ],
    providers: [SpecialCharPipe, PermissionsPipe, SafehtmlPipe, UniversityCommunicationService],
    imports: [
        CommonModule,
        MatMenuModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        MatNativeDateModule,
        TranslateModule,
        ToastrModule,
        NgxDropzoneModule,
        NgxMatSelectSearchModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMatSelectSearchModule, 
        MatSelectModule,
        NgxMatSelectSearchModule,
        NgxEditorModule,
        // NgxEditorModule,
        ClipboardModule,
        MatSelectInfiniteScrollModule,
        QuillModule,
        TagInputModule,
        NgScrollbarModule,
        CdkAccordionModule
    ],
    exports: [
        MatMenuModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        MatNativeDateModule,
        TranslateModule,
        ToastrModule,
        ImageUploadComponent,
        NumericInputDirective,
        NoDataFoundComponent,
        NgxDropzoneModule,
        NgxMatSelectSearchModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMatSelectSearchModule, 
        MatSelectModule,
        NgxMatSelectSearchModule,
        NgxEditorModule,
        ClipboardModule,
        MatSelectInfiniteScrollModule,
        QuillModule,
        DocumentFormatPipe,
        PermissionsPipe,
        SafehtmlPipe,
        SearchMailComponent,
        TagInputModule,
        MailSidebarComponent,
        EmailActivityComponent,
        NgScrollbarModule,
        CdkAccordionModule
    ]
})
export class SharedModule {}
