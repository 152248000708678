<mat-card class="email-warn-card tagus-card text-center">
    <mat-card-content class="example-buttons">
        <img src="/assets/img/svg/crizac-logo.svg" alt="" class="logo-img">
        <ng-container *ngIf="data?.type === mail_common.discard">
            <h5 class="p-20">
                You are closing a message that has not been sent.
            </h5>
            <button mat-raised-button class="gray d-block mt-20" (click)="close(mail_common.discard)">
                Discard Changes
            </button>
            <button mat-raised-button class="gray d-block mt-20" (click)="saveDraft(data)">
                Save as Draft
            </button>
            <button mat-raised-button class="gray d-block mt-20" (click)="close(mail_common.continue)">
                Continue Writing
            </button>
        </ng-container>
        <ng-container *ngIf="data?.type === mail_common.missing_subject">
            <h5 class="p-20">
                Missing Subject.
            </h5>
            <h5 class="ps-20 pe-20 fw-medium">
                Do you want to send this message without a subject?
            </h5>
            <button mat-raised-button class="tagus d-block mt-30" (click)="close(mail_common.send)">
                Send
            </button>
            <button mat-raised-button class="gray d-block mt-20" (click)="close(mail_common.dont_send)">
                Don't Send
            </button>
        </ng-container>
        <ng-container *ngIf="data?.type === mail_common.large_file">
            <h5 class="p-20 text-danger">
                Large File Size.
            </h5>
            <h5 class="ps-20 pe-20 fw-medium">
                Attachments is larger than 25MB.
            </h5>
            <button mat-raised-button class="gray d-block mt-20" (click)="close(mail_common.large_file)">
                Close
            </button>
        </ng-container>
    </mat-card-content>
</mat-card>