export const environment = {
    // apiURL: 'http://192.168.0.27:5000/',
    // apiURL: 'https://api-dev.crizac.com/',
    production: false,
    apiURL: 'https://api-dev.crizac.com/',
    fileBaseUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/system',
    callBaseUrl: 'https://www.crizac.co.uk/yeasterdownload/',
    adminWebUrl: 'https://console-dev.crizac.com/',
    oneSignalAppId: '0da6bcf9-4fc0-4282-afba-3c8c152bc51a'
};
