import { PaginationType } from "../interfaces/pagination/pagination-type"

export const STATUS = [
    'Enabled', 
    'Disabled'
]

export const TWO_FACTOR_AUTHENTICATION = [
    'Enabled', 
    'Disabled'
]

export const LOGIN_IP = [
    'Enabled', 
    'Disabled'
]

export const SMTP_STATUS = [
    {id: true, value: 'Enabled'},
    {id: false, value: 'Disabled'},
]

export const LINK_STATUS = [
    {_id: true, value: 'Enabled'},
    {_id: false, value: 'Disabled'},
]

export const AGENT_COMMISSIOM = [
    {id: true, value: 'Enabled'},
    {id: false, value: 'Disabled'},
]

export const ASSIGN_STATUS = [
    {id: 1, value: 'Assign'},
    {id: 2, value: 'Re-Assign'},
]

export const INTERVIEW_TYPE_STATUS = [
    {id: 1, value: 'Final'},
    {id: 2, value: 'High Commission Credibility'},
    {id: 3, value: 'Mock'},
    {id: 4, value: 'Pre Application Credibility'},
]

export const INTERVIEW_MODE_STATUS = [
    {id: 1, value: 'Zoom'},
    {id: 2, value: 'Skype'},
    {id: 3, value: 'Click To Call'},
    {id: 4, value: 'Other'},
]

export const APP_STATUS = [
    {id: true, value: 'Application Processed'},
    {id: false, value: 'Application Not Processed'},
]

export const TIER_STATUS = [
    {id: 1, value: '1'},
    {id: 2, value: '2'},
    {id: 3, value: '3'},
    {id: 4, value: '4'},
]

export const CAT_STATUS = [
    {id: 5,   value: 'Case Closed'},
    {id: 7,   value: 'Application Processed'},
    {id: 19,  value: 'Application Not Processed'},
    {id: 101, value: ' Application Ok for Processing '}
]

export const CPT_STATUS = [
    {id: 5,   value: 'Case Closed'},
    {id: 7,   value: 'Application Processed'},
    {id: 19,  value: 'Application Not Processed'},
    {id: 101, value: ' Application Ok for Processing '}
]

export const SMTP_TYPE = [
    {id: 'master', value: 'Master'},
    {id: 'other', value: 'Other'},
]

export const SEATS = [
    '1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20'
]

export const COA_List = ['AOA', 'COA', 'ORIGINAL', 'UCAS'];

export const Married = [
    {id: 'Married', value: 'Yes'},
    {id: 'Unmarried', value: 'No'},
]

export const InterviewType = [
    'Pre Application Credibility',
    'High Application Credibility',
    'Mock',
    'Final'
]

export const InterviewMode = [
    'Zoom',
    'Skpye',
    'Click to call',
    'Other'
]

export const InterviewModeMapping = {
    zoom: 'Zoom',
    skype: 'Skpye',
    call: 'Click to call',
    other: 'Other'
}

export const PaginationConfig: PaginationType = {
    size: 10,
    total: 0,
    skip: 0,
    limit: 10
}

export const DgreeType= [
    {name: 'Degree',id:1},
    {name: 'Others',id:2},
    {name: 'PG',id:3},
    {name: 'UG',id:4},
    {name: 'Tenth',id:5},
    {name: 'Twelve',id:6}
]

export const FilterType = {
    reset: 'reset',
    apply: 'apply'
}

export const DocRestrictionsExtensions = [
    '.jpg', 
    '.jpeg', 
    '.png', 
    '.docx', 
    '.txt', 
    '.pdf'
]

export const NoOfQualYears = ['1','2','3','4','5','6','7','8','9','10'];

export const SearchEntity = {
    debounce_time: 1000,
    clear_search_input: false
} 

export const ApplicationType = [
    {id: 'Crizac Application', name: 'Crizac Application'},
    {id: 'Other Application', name: 'Other Application'}
]

export const EmailActivityType = {
    management: {id: 1, value: 'management'},
    junk: {id: 2, value: 'junk'},
    urm: {id: 3, value: 'urm'},
    important: {id: 11, value: 'important'},
    done: {id: 12, value: 'done'},
}

export const PaginationLimit = {
    university: 500
}

export const InputType = [
    {id: 'text', value: 'Text'},
    {id: 'dropdown', value: 'Dropdwon'}
]

export const OptionInputTypes = {
    text: 'text',
    dropdown: 'dropdown'
}

export const EmailRegex = /^\S+@\S+\.\S+$/;