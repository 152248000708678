import { Pipe, PipeTransform } from '@angular/core';
import {decode} from 'html-entities';

@Pipe({
  name: 'specialChar'
})
export class SpecialCharPipe implements PipeTransform {

  transform(value: string): any {
    // return value ? 
    //   (value.replaceAll(';p', '')
    //     .replaceAll(';/p', '')
    //     .replaceAll('&amp', '')
    //     .replaceAll('&amp;', '')
    //     .replaceAll('amp;', '')
    //     .replaceAll(';amp', '')
    //     .replaceAll('lt;', '')
    //     .replaceAll(';lt', '')
    //     .replaceAll('lt', '')
    //     .replaceAll('gt', '')
    //     .replaceAll(';gt', '')
    //     .replaceAll('gt;', '')
    //     .replaceAll(';', ' ')
    //     .replaceAll('h1', '')
    //     .replaceAll('/h1', '')
    //     .replaceAll('strong', '')
    //     .replaceAll('/strong', '')
    //     .replaceAll(';/strong', '')
    //     .replaceAll(';/', '')
    //     .replaceAll('/', '')
    //     .replaceAll('#39;', "'")
    //     .replaceAll('#39', "'")
    //     .replaceAll('&nbsp;', '')
    //     .replaceAll('nbsp;', '')
    //     .replaceAll('nbsp', '')
    //     .replaceAll(';nbsp', '')
    //     .replaceAll('<p>', '')
    //     .replaceAll('</p>', '')
    //     .replaceAll('<>', '')
    //     .replaceAll('ul', '')
    //     .replaceAll('li', '')
    //     .replaceAll(/[^\w\s]/gi, " ")

    //     .replaceAll('3Cp 3E', '')
    //     .replaceAll('20', ' ')
    //     .replaceAll('3C 2Fp 3E 0A 0A', '')
    //     .replaceAll('26 3B', '')
    //     .replaceAll('3Cbr', '')
    //     .replaceAll('2F 3E 0A', '')
    //     .replaceAll('2C', '')
    //     .replaceAll('3A', '')
    //     .replaceAll('2B91337114', '')
    //     .replaceAll('51', '')
    //     .replaceAll('51', '')
    //     .replaceAll('3C', '')
    //     .replaceAll('2Fp', '')
    //     .replaceAll('3E', '')
    //     .replaceAll('0A', '')
    //     ) : '';

    if (value) {
      var entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"']
      ];
  
      for (var i = 0, max = entities.length; i < max; ++i)
        value = value.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);
      }

      value = value.replaceAll('&amp;nbsp;', ' ').
                    replaceAll('&amp;quot;', '"').
                    replaceAll('&amp;rsquo;', "'").
                    replaceAll('&amp;lsquo;', "'").
                    replaceAll('&#39;', "'").
                    replaceAll('&ndash;', "-").
                    replaceAll('&amp;', "&");

      return value;
    }
}
