<mat-card-content>
    <div class="row" >
        <div class="col-lg-12 col-md-12 col-sm-12">
            <mat-card class="mb-25 tagus-card">
                <mat-card-header>
                </mat-card-header>
                <mat-card-content>
                    <div class="empty-state">
                        <div class="empty-state__content">
                          <!-- <div class="empty-state__icon"> -->
                            <img src="/assets/img/svg/crizac-logo.svg" width="200" alt="">
                          <!-- </div> -->
                          <div class="empty-state__message">Loading...</div>
                          <div class="empty-state__help">
                          </div>
                        </div>
                      </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</mat-card-content>