import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { confirmedValidator } from 'src/app/core/helpers/password-validator';
import { ApiResponse } from 'src/app/core/interfaces/api-response/ApiResponse.interface';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';
import { CustomValidatorService } from 'src/app/core/services/custom-validator/custom-validator.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { LocalStorageService } from 'src/app/core/services/localStorage/localStorage.service';
import { ToasterService } from 'src/app/core/services/toaster/toaster.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  hide_current_pwd = true;
  hide_pwd = true;
  hide_confirm_pwd = true;
  update_pwd_form: FormGroup;
  is_current_pwd_valid = false;
  private _unsubscribeAll: Subject<any> = new Subject();
  token: string;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private _fb: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _loaderService: LoaderService,
    private _toasterService: ToasterService,
    private _customValidator: CustomValidatorService,
    private _localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.initiateUpdatePwdForm();
    this.token = this._localStorageService.getKey('access_token');
    this.update_pwd_form.get('current_password').valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((val: string) => {
        if (val.length >= 3) {
          this.checkCurrentPassword(val);
        }
      })
  }

  close(val?: boolean) {
    this.dialogRef.close(val);
  }

  initiateUpdatePwdForm() {
    this.update_pwd_form = this._fb.group({
      current_password: ['', Validators.required],
      password: ['', Validators.compose([
          Validators.required, 
          Validators.minLength(8), 
          this._customValidator.patternValidator()
        ])
      ],
      confirmpassword: ['', Validators.required]
    },
    {
      validators: confirmedValidator('password', 'confirmpassword'),
    })
  }

  checkCurrentPassword(current_pwd: string) {
    this._loaderService.showLoader();
    this._authenticationService.checkPassword({password: current_pwd, token: this.token})
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((result: ApiResponse<any>) => {
      if (result && result.statusCode === 200) {
        // this.update_pwd_form.get('current_password').setErrors({'invalid': false});
        this.update_pwd_form.get('current_password').setValidators([Validators.required]);
        this.update_pwd_form.get('current_password').updateValueAndValidity();
      } else {
        this.is_current_pwd_valid = false;
        this.update_pwd_form.get('current_password').setErrors({'invalid': true});
      }
      this._loaderService.hideLoader();
    }, (err) => {
      this.update_pwd_form.get('current_password').setErrors({'invalid': true});
      this._loaderService.hideLoader();
    })
  }

  updatePassword() {
    if (this.update_pwd_form.invalid) {
      return;
    }
    delete this.update_pwd_form.value.current_password;
    
    this._loaderService.showLoader();
    this._authenticationService.resetPassword({...this.update_pwd_form.value, token: this.token})
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((result: ApiResponse<any>) => {
      if (result.statusCode === 200) {
        this._loaderService.hideLoader();
        this._toasterService.showSuccess('SUCCESS', 'Password changed successfully');
        this.close(true);
      }
    }, (err) => {
      this._loaderService.hideLoader();
    })
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
