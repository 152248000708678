
  <mat-form-field appearance="outline" class="w-100">
    <div>
        <input matInput [value]="selectedFileName?.name ? selectedFileName?.name : 'Select Image'" />

      <input
        type="file"
        id="fileInput"
        name="fileInput"
        accept="image/*" multiple
        (change)="selectFiles($event)"
      />
    </div>
  </mat-form-field>

  <div class="preview mb-15" style="position: relative;" *ngIf="selectedFile">
    <img [src]="preview" >
    <mat-icon color="primary" style="position: absolute; top: 5px; right: 5px;" (click)="removeFile()">delete</mat-icon>
  </div>
