import { Injectable } from '@angular/core';
import { HttpClientService } from '../httpClient/httpClient.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { universityCommunicationApi } from '../../constants/university-communication-api';
import { MatDialog } from '@angular/material/dialog';
import { SelectionWarningComponent } from 'src/app/views/university-communications/shared/selection-warning/selection-warning.component';
import { PaginationType } from '../../interfaces/pagination/pagination-type';

@Injectable({
  providedIn: 'root'
})
export class UniversityCommunicationService {

  checkMails = new BehaviorSubject<boolean>(false);
  checkMails$ = this.checkMails.asObservable();

  constructor(
    private httpClientService: HttpClientService,
    public dialog: MatDialog
  ) { }

  getIncomingMail(pagination: any, type: any, search: string, domain_mail: any): Observable<any> {
    let query = '?';
    query += `type=${type}&`;
    if (search) {
      query += `search=${search}&`;
    }
    if (domain_mail) {
      query += `domain=${domain_mail.domain}&email=${domain_mail.mail}&`;
    }
    query += `skip=${pagination.skip}&limit=${pagination.limit}`;

    return this.httpClientService.get(`${universityCommunicationApi.getIncomingMail}${query}`);
  }

  getMailDetailsById(id: any): Observable<any> {
    const url = `${universityCommunicationApi.getMailDetailsById}/${id}`;
    return this.httpClientService.post(url,{});
  }

  sendMail(params: any, payload: any): Observable<any> {
    return this.httpClientService.upload(
      `${universityCommunicationApi.sendMail}${params}`,
      payload
    );
  }

  assignMail(payload: any) {
    return this.httpClientService.put(`${universityCommunicationApi.assignMail}${payload}`, {});
  }

  bulkUpdate(payload: any) {
    return this.httpClientService.put(`${universityCommunicationApi.bulkUpdate}${payload}`, {});
  }

  getMailsByAppId(id: any, pagination: PaginationType): Observable<any> {
    const url = `${universityCommunicationApi.getMailsByAppId}${id}&skip=${pagination.skip}&limit=${pagination.limit}`;
    return this.httpClientService.get(url);
  }

  updateCommunicationMail(id: any, payload: any) {
    return this.httpClientService.put(`${universityCommunicationApi.updateCommunicationMail}${id}`, payload);
  }

  getDashboardCounts(): Observable<any> {
    const url = `${universityCommunicationApi.getDashboardCounts}`;
    return this.httpClientService.get(url);
  }

  downloadMail(payload: any): Observable<any> {
    const url = `${universityCommunicationApi.downloadMail}${payload.messageId}&subject=${payload.subject}`;
    return this.httpClientService.eml(url, {});
  }

  uploadMail(payload: any): Observable<any> {
    const url = `${universityCommunicationApi.uploadMail}?agentId=${payload.agentId}&messageId=${payload.messageId}&subject=${payload.subject}`;
    return this.httpClientService.eml(url, {});
  }
  
  openWarningPopup(type: string) {
    const dialogRef = this.dialog.open(SelectionWarningComponent, {
      width: '30%',
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '100ms',
      data: {
        type: type
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
    dialogRef.afterOpened().subscribe((result) => {
      setTimeout(() => {
         dialogRef.close();
      }, 3000)
    })
  }

  updateRead(message_id: any, read: any) {
    return this.httpClientService.put(`${universityCommunicationApi.updateRead}${message_id}&isRead=${read}`, {});
  }

  createFolder(user_id: string, payload: any) {
    return this.httpClientService.put(`${universityCommunicationApi.createFolder}${user_id}`, payload);
  }
  
  saveDraft(params: any, payload: any): Observable<any> {
    return this.httpClientService.upload(
      `${universityCommunicationApi.saveDrafts}${params}`,
      payload
    );
  }

  oneSignalSubscribe(payload: {deviceType:string, userId:string, playerId: string}): Observable<any> {
    return this.httpClientService.post(
      `${universityCommunicationApi.OneSignalSubscribe.replace(':deviceType',payload?.deviceType).replace(':userId',payload?.userId).replace(':playerId',payload?.playerId)}`,
      {}
    );
  }

}
