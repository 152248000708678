import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomizerSettingsService } from '../app/components/customizer-settings/customizer-settings.service';
import { ToggleService } from '../app/components/common/header/toggle.service';
import { OneSignal } from 'onesignal-ngx';
import { UniversityCommunicationService } from './core/services/university-communication/university-communication.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'Tagus - Material Design Angular Admin Dashboard Template';
    isToggled = false;
    
    constructor(
        public router: Router,
        private toggleService: ToggleService,
        public themeService: CustomizerSettingsService,
        // private oneSignal: OneSignal,
        private _connectionService: UniversityCommunicationService
    ) {
        this.toggleService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });

       
    }

    ngOnInit(): void {
    }

    

    // onHandleTag(tag: string){
    //     this.oneSignal.Notifications.addEventListener('permissionChange', (event) => {
    //         console.log("The notification was clicked!", event);
    //     });
    // }

    // handleSubscription() {
    //     const OneSignal = (window as any).OneSignal || [];
    //     OneSignal.push(() => {
    //     //   this.oneSignal.Session('subscriptionChange', (isSubscribed: boolean) => {
    //     //     if (isSubscribed) {
    //     //       // Get the user/player ID after subscription
    //     //       OneSignal.getUserId((playerId: string) => {
    //     //         console.log('OneSignal User ID (playerId):', playerId);
    //     //         // You can use playerId for your application logic here
    //     //       });
    //     //     } else {
    //     //       console.log('User is not subscribed');
    //     //     }
    //     //   });
    
    //       // Optional: Trigger the prompt to subscribe
    //       OneSignal.showSlidedownPrompt().then(() => {
    //         console.log('Prompt for push notifications shown');
    //       });
    //     });
    // }

    toggleRightSidebarTheme() {
        this.themeService.toggleRightSidebarTheme();
    }

    toggleHideSidebarTheme() {
        this.themeService.toggleHideSidebarTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }


}
