<div class="create-mail-box scrollable-dialog">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">
            {{email_data?.type === 'reply' ? 'Reply' : email_data?.type === 'replyAll' ? 'Reply All' : email_data?.type
            === 'forward' ? 'Forward' : 'Compose'}}
        </h4>
        <div>
            <button mat-flat-button type="button" class="tagus me-10" (click)="sendMail()">Send</button>
            <button mat-flat-button type="button" color="warn" class="me-20" (click)="close()">Discard</button>
            <!-- <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button> -->
        </div>
    </div>
    <form [formGroup]="compose_email_form" (ngSubmit)="sendMail()" class="form-wrapper">
        <div class="w-100 d-flex">
            <div class="alert alert-tagus p-10 fw-semibold mt-10" role="alert">
                <span>From</span>
            </div>
            <div class="tagus-form-group without-icon ms-10 w-100">
                <mat-form-field appearance="fill">
                    <!-- <mat-label>From</mat-label> -->
                    <mat-select class="form-control" formControlName="from">
                        <mat-option *ngFor="let align_mails of align_inbox_mail" [value]="align_mails.mail">
                            {{align_mails.mail}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="w-100 d-flex">
            <div class="alert alert-tagus p-10 fw-semibold" role="alert">
                To
            </div>
            <div class="tagus-form-group without-icon w-100 ms-10">
                <tag-input [formControlName]="'to'" [editable]='true' placeholder="" secondaryPlaceholder=""
                    [errorMessages]="errorMessages"
                    [validators]="user_details?.externalEmailAccess == 'true' ? validators :customValidators"
                    [addOnBlur]="true" [addOnPaste]="true" class="p-0">
                </tag-input>
            </div>
            <a href="javascript:void(0)" class="pt-10" (click)="showBCC()" *ngIf="!is_bcc">Bcc</a>
        </div>
        <div class="w-100 d-flex">
            <div class="alert alert-tagus p-10 fw-semibold" role="alert">
                Cc
            </div>
            <div class="tagus-form-group without-icon mb-10 ms-10 w-100">
                <tag-input formControlName="cc" [editable]='true' placeholder="" secondaryPlaceholder=""
                    [errorMessages]="errorMessages" [validators]="validators" [addOnBlur]="true" [addOnPaste]="true">
                </tag-input>
            </div>
        </div>
        <!-- bcc input -->
        <div class="w-100 d-flex" *ngIf="is_bcc">
            <div class="alert alert-tagus p-10 fw-semibold" role="alert">
                Bcc
            </div>
            <div class="tagus-form-group without-icon mb-10 ms-10 w-100">
                <tag-input formControlName="bcc" [editable]='true' placeholder="" secondaryPlaceholder=""
                    [errorMessages]="errorMessages" [validators]="validators" [addOnBlur]="true" [addOnPaste]="true">
                </tag-input>
            </div>
        </div>
        <div class="tagus-form-group without-icon mt-10">
            <mat-form-field appearance="fill">
                <mat-label>Subject</mat-label>
                <input matInput formControlName="subject" />
            </mat-form-field>
        </div>
        <div class="tagus-form-group mb-20">
            <div class="NgxEditor__Wrapper">
                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                <ngx-editor [editor]="editor" name="html" [disabled]="false" [placeholder]="" formControlName="html">
                </ngx-editor>
            </div>
            <mat-error *ngIf="is_submitted && compose_email_form.get('html').hasError('required')">
                Please type something
            </mat-error>
        </div>

        <div class="tagus-form-group">
            <input type="file" style="display: none;" #open_file multiple (change)="selectFiles($event)">
            <mat-form-field appearance="fill">
                <i class="ri-attachment-line"></i>
                <input matInput placeholder="Choose File" (click)="open_file.click()">
            </mat-form-field>
        </div>
        <div class="w-100 d-flex">
            <div class="alert alert-secondary d-flex 
                        justify-content-space-between 
                        align-items-center me-5" role="alert" *ngFor="let attachment of upload_files; index as i"
                [matTooltip]="attachment?.name?.length > 15 ? attachment?.name : ''">
                {{attachment?.name | slice:0:15}}
                <span *ngIf="attachment?.name?.length > 15">...</span>
                <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert"
                    aria-label="Close" (click)="removeDoc(i)">
                    <i class="ri-close-line"></i>
                </button>
            </div>
        </div>
        <div class="w-100 d-flex">
            <div class="alert alert-secondary d-flex 
                        justify-content-space-between 
                        align-items-center me-5" role="alert"
                *ngFor="let def_attachment of default_attachment; index as i">
                <a href="javascript:void(0)" (click)="viewAttachment(def_attachment)"
                    [matTooltip]="def_attachment?.fileName?.length > 15 ? def_attachment?.fileName : ''">
                    {{def_attachment?.fileName | slice:0:15}}
                    <span *ngIf="def_attachment?.fileName?.length > 15">...</span>
                </a>
                <button type="button" class="close bg-transparent p-0 border-none" data-dismiss="alert"
                    aria-label="Close" (click)="removeDefaultAttachment(i); $event.stopPropagation()">
                    <i class="ri-close-line"></i>
                </button>
            </div>
        </div>
        <div class="btn-box" *ngIf="false">
            <button mat-flat-button type="submit" class="tagus">
                <!-- {{
                    email_data?.type === 'reply' ? 'Reply' : email_data?.type === 'forward' ? 'Forward' : 'Send'
                }} -->
                Send
            </button>
            <button mat-flat-button type="button" color="warn" (click)="close()">Discard</button>
        </div>
    </form>
</div>