import { Pipe, PipeTransform } from "@angular/core";
import { AuthenticationService } from "../services/auth/authentication.service";
import { permissionEntity } from "../constants/permission";

@Pipe({
  name: "permissions",
})
export class PermissionsPipe implements PipeTransform {
  permission_access_provided: any;
  constructor(
    private _authenticationService: AuthenticationService
    ) { }

  transform(value: any, arg?: any): boolean {
    this.permission_access_provided = this._authenticationService.getPermissions();
    if (this.permission_access_provided?.length) {
      if (Array.isArray(value)) { 
        return this.permission_access_provided.some((key: any) =>
            value.includes(key.module.toLowerCase()) && key.access.includes(arg ?? permissionEntity.access.read)
        );
      } else {
        return this.permission_access_provided.some((key: any) =>
          new Array(value).includes(key.module.toLowerCase()) && key.access.includes(arg ?? permissionEntity.access.read)
        );
      }
    }
    
    return false;

    // if (Array.isArray(value) && this.permission_access_provided) {
      // return this.permission_access_provided.some((key: any) =>
      //   value.includes(key.module.toLowerCase()) && key.access.includes(arg || permissionEntity.access.read)
      // );
    // } 
    // else if (typeof value === "string") {
    //   if (arg) {
    //     let permission = this.permission_access_provided.filter((key: any) => {
    //       return key.module.toLowerCase() == value.toLowerCase();
    //     });

    //     if (permission.length) {
    //       return permission[0].access.indexOf(arg) > -1;
    //     } else {
    //       return false;
    //     }
    //   } else {
    //     return this.permission_access_provided?.some((key: any) =>
    //       value.includes(key.module.toLowerCase())
    //     );
    //   }
    // } else {
    //   return false;
    // }
  }
}
