import { Injectable } from '@angular/core';
import { HttpClientService } from '../httpClient/httpClient.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { usersApis } from '../../constants/users-api';
import { HttpClient } from '@angular/common/http';
import { permissionEntity } from '../../constants/permission';
import { PermissionsPipe } from '../../pipe/permissions.pipe';
import { specificUserParams, userParams } from '../../helpers/helperfunc';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    callListOfUploadedDocs = new BehaviorSubject<boolean>(false);
    callListOfUploadedDocs$ = this.callListOfUploadedDocs.asObservable();
    permissions_entity = permissionEntity;

    constructor(
        private httpClientService: HttpClientService,
        private _permissionPipe: PermissionsPipe // dont remove this
    ) {}

    getUsersList(pagination: any, type?: string, filters?: any, search?: string): Observable<any> {
        let query = '?';
        if (type) {
            if (type === 'urm') {
                query += 'urm=true&';
                query += 'status=true&'
            } else {
                query += 'urm=true&';
                query += 'status=true&'
            }
        }
        if (filters) {
            query += filters;
        }
        if (search) {
            query += `name=${search}&`;
        }
        query += userParams.call(this, this.permissions_entity.modules.users);
        query += `skip=${pagination.skip}&limit=${pagination.limit}`;

        return this.httpClientService.get(`${usersApis.getUsersList}${query}`);
    }

    postUsers(payload: any): Observable<any> {
        return this.httpClientService.post(usersApis.createUser, payload);
    }

    updateUsers(id: string, payload: any): Observable<any> {
        const url = `${usersApis.updateUser}?Id=${id}`;
        return this.httpClientService.put(url, payload);
    }

    deleteUser(id: string): Observable<any> {
        return this.httpClientService.delete(
            usersApis.deleteUsers.replace(':userId', id)
        );
    }

    getUserById(id:string): Observable<any> {
        return this.httpClientService.get(
            usersApis.getUserById.replace(':userId', id)
        );
    }
    
    uploadDocById(id: string, payload: any): Observable<any> {
        const url = `${usersApis.uploadDocumentById}?userId=${id}`;
        return this.httpClientService.upload(url, payload);
    }
    removeDocById(payload: any): Observable<any> {
        const url = `${usersApis.removeDocumentById}`;
        return this.httpClientService.post(url, payload);
    }

    getUsersListFilter(params: { skip: number; limit: number; email: string, roleId?;status;twoFactorAuth;
        jobRoleId; employeeCabinId; name;employeDesignation? }): Observable<any> {
        let url = `${usersApis.getUsersList}?`;
        // console.log(params);
        
       // Create a new instance of URLSearchParams
        const searchParams = new URLSearchParams();

        // Add parameters to the URLSearchParams if they are defined
        for (const [key, value] of Object.entries(params)) {
            if (value !== undefined) {
            searchParams.set(key, value);
            }
        }

        // Append the URLSearchParams to the URL
        // url += `&${searchParams.toString()}`;
        url += `${searchParams.toString()}`;
        return this.httpClientService.get(url);
    }

    resendInvite(id: string): Observable<any> {
        return this.httpClientService.post(usersApis.resendInvite.replace(':userId', id),{});
    }

    // uploadProfilePic(id: string, payload: any): Observable<any> {
    //     const url = `${usersApis.uploadProfilePic}`;
    //     return this.httpClientService.upload(url, payload);
    // }
}
