import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { urlRoutes } from './core/constants/url-route.constant';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthenticatedGuard } from './core/guard/authenticated.guard';
import { UnauthorisedComponent } from './views/components/unauthorised/unauthorised.component';

const routes: Routes = [
    // TODO: we are using the below path for temporary purpose
    {
        path: '',
        // redirectTo: urlRoutes.mainLayout,
        redirectTo: '',
        pathMatch: 'full',
    },
    {
        path: urlRoutes.authentication,
        loadChildren: () =>
            import('./views/authentication/authentication.module').then(
                (m) => m.AuthenticationModule
            ),
            canActivate:[AuthenticatedGuard]
    },
    {
        // path: urlRoutes.mainLayout,
        path: '',
        loadChildren: () =>
            import('./views/layout/layout.module').then((m) => m.LayoutModule),
            canActivate:[AuthGuard]
    },
    // { path: 'agent-signup', component: SignUpComponent },
    { path: 'unauthorised', component: UnauthorisedComponent},
    // This line will remain down from the whole pages component list
    { path: '**', component: NotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
