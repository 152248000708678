<div class="change-pwd-dialog scrollable-dialog">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Change Password</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>

    <mat-card class="tagus-card add-user-wrapper">
        <mat-card-content>
            <form [formGroup]="update_pwd_form" (ngSubmit)="updatePassword()">
                <!-- <h5 class="fw-semibold mb-8">Update Password</h5>
                <p>Update your password from here.</p> -->
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="tagus-form-group">
                            <label class="d-block mb-12 fw-semibold gray-color">Current Password</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-lock-line"></i>
                                <!-- <mat-label>Type password</mat-label> -->
                                <input matInput [type]="hide_current_pwd ? 'password' : 'text'" formControlName="current_password">
                                <button mat-icon-button 
                                    matSuffix 
                                    (click)="hide_current_pwd = !hide_current_pwd" 
                                    [attr.aria-label]="'Hide password'" 
                                    [attr.aria-pressed]="hide_current_pwd"
                                    >
                                    <mat-icon>{{hide_current_pwd ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-error *ngIf="update_pwd_form.get('current_password').hasError('required')">
                                    Current password is required
                                </mat-error>
                                <mat-error *ngIf="update_pwd_form.get('current_password').hasError('invalid')">
                                    Invalid current password
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="tagus-form-group">
                            <label class="d-block mb-12 fw-semibold gray-color">New Password</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-lock-line"></i>
                                <!-- <mat-label>Type password</mat-label> -->
                                <input matInput [type]="hide_pwd ? 'password' : 'text'" formControlName="password">
                                <button mat-icon-button 
                                    matSuffix 
                                    (click)="hide_pwd = !hide_pwd" 
                                    [attr.aria-label]="'Hide password'" 
                                    [attr.aria-pressed]="hide_pwd"
                                    >
                                    <mat-icon>{{hide_pwd ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-error *ngIf="update_pwd_form.get('password').hasError('required')">
                                    Password is required
                                </mat-error>
                                <mat-error *ngIf="update_pwd_form.get('password').hasError('minlength')">
                                    Password should be atleast 8 characters long
                                </mat-error>
                                <mat-error *ngIf="update_pwd_form.get('password').hasError('validUppercase')">
                                    At least one uppercase required
                                </mat-error>
                                <mat-error *ngIf="update_pwd_form.get('password').hasError('validLowercase')">
                                    At least one lowecase required
                                </mat-error>
                                <mat-error *ngIf="update_pwd_form.get('password').hasError('validNum')">
                                    At least one number required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="tagus-form-group">
                            <label class="d-block mb-12 fw-semibold gray-color">Confirm New Password</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-lock-line"></i>
                                <!-- <mat-label>Type password</mat-label> -->
                                <input matInput [type]="hide_confirm_pwd ? 'password' : 'text'" formControlName="confirmpassword">
                                <button mat-icon-button 
                                    matSuffix 
                                    (click)="hide_confirm_pwd = !hide_confirm_pwd" 
                                    [attr.aria-label]="'Hide password'" 
                                    [attr.aria-pressed]="hide_confirm_pwd"
                                    >
                                    <mat-icon>{{hide_confirm_pwd ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-error 
                                    *ngIf="update_pwd_form.get('confirmpassword').hasError('confirmedValidator') ||
                                        update_pwd_form.get('confirmpassword').hasError('required')">
                                    Confirm new password does not match
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-space-between mt-20">
                    <button mat-raised-button 
                        class="tagus fw-semibold"
                        type="submit">
                        Change Password
                    </button>
                    <!-- <a routerLink="/forgot-password" class="d-inline-block main-color fw-semibold">Forgot your password?</a> -->
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>