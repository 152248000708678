import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safehtml'
})
export class SafehtmlPipe implements PipeTransform {
  sanitizer: any;
  constructor(private sanitized: DomSanitizer) { }

  transform(value: any): any {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
