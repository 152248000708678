import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { EmailActivityType, SearchEntity } from 'src/app/core/constants/dropdown-contants';
import { filterValue } from 'src/app/core/helpers/helperfunc';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { ToasterService } from 'src/app/core/services/toaster/toaster.service';
import { UniversityCommunicationService } from 'src/app/core/services/university-communication/university-communication.service';
import { UsersService } from 'src/app/core/services/users/users.service';

@Component({
  selector: 'app-share-mail',
  templateUrl: './share-mail.component.html',
  styleUrls: ['./share-mail.component.scss']
})
export class ShareMailComponent implements OnInit, OnDestroy {
  share_user_form: FormGroup;
  user_list: any;
  user_name: any[] = [];
  public filtered_user: ReplaySubject<any> = new ReplaySubject<any>(1);
  public user_filter_control: FormControl<string> = new FormControl<string>('');

  user_pagination = {
    skip: 0,
    limit: 100
  }
  total_user_count: number = 0;
  user_offset: number = 0;
  filterValue = filterValue;
  search_entiry = SearchEntity
  private _unsubscribeAll: Subject<any> = new Subject();
  email_activity_type = EmailActivityType;
  search_user: string = '';
  find_extisting_ids: any[] = [];
  find_extisting_users: any[] = [];
  user_details: any;

  constructor(
    public dialogRef: MatDialogRef<ShareMailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private _loaderService: LoaderService,
    private _userService: UsersService,
    private _universityCommunicationService: UniversityCommunicationService,
    private _toasterService: ToasterService,
    private _authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.user_details = this._authService.getLoginUserDetails();
    this.initiateForm();
    this.getUser();
    this.filterURM();
  }

  initiateForm() {
    this.share_user_form = this._fb.group({
      user_id: ['', Validators.required]
    })

    if (this.data?.shareUsers?.length) {
      this.data.shareUsers = this.data?.shareUsers?.filter(element => element?.id);
      if (this.data?.shareUsers?.length) {
        this.find_extisting_users = this.data?.shareUsers?.map((element: any) => {
          return (
            { 
              name: `${element?.userDetails?.firstName ?? ''} ${element?.userDetails?.lastName ?? ''}`, 
              id: element?.id,
              role: element?.jobRoleDetails?.name,
            }
          )
      })?.filter(element => element?.id !== this.user_details?.id);
      if (this.find_extisting_users?.length) {
        this.find_extisting_ids = this.find_extisting_users?.map((element: any) => {
          return element.id;
        })
      }
      this.share_user_form.get('user_id').setValue(this.find_extisting_ids);
      }
    }
  }

  close(value?: any) {
    this.dialogRef.close(value);
  }

  getUser() {
    this._loaderService.showLoader();
    this._userService.getUsersList(this.user_pagination, '', '', this.search_user)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((resp: any) => {
      if (resp && resp?.data?.data?.length) {
        let user_list = resp?.data;
        this.user_list = user_list?.data?.map((v) => { 
          return (
            { 
              name: `${v?.userDetails?.firstName ?? ''} ${v?.userDetails?.lastName ?? ''}`, 
              id: v?.id,
              role: v?.roles?.name,
            }) 
        })?.filter(element => this.find_extisting_ids.indexOf(element?.id) === -1 && element?.id !== this.user_details?.id);
        this.total_user_count = user_list?.total;
      }
      this._loaderService.hideLoader();
    }, (err) => {
      this._loaderService.hideLoader();
    })
  }

  filterURM() {
      this.user_filter_control.valueChanges
      .pipe(
        debounceTime(this.search_entiry.debounce_time),
        distinctUntilChanged(),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((res: string) => {
        this.search_user = res;
        this.getUser();
      });
  }

  getNextURM() {
    this.user_pagination.skip = 0;
    this.user_pagination.limit += 100;
    this.user_offset += this.user_pagination.limit;
    this.getUser();
  }

  share() {
    if (this.share_user_form.get('user_id').invalid) {
      this._universityCommunicationService.openWarningPopup('users');
      return;
    }
    if (!this.data.selected_mails) {
      this._universityCommunicationService.openWarningPopup('');
      this.close();
      return;
    }

    this.close(this.share_user_form.get('user_id').value);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
