import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss']
})
export class UnauthorisedComponent {
  home:string = '/';
  constructor(
    private _router: Router
  ){

  }
  navigateToHome(){
    this._router.navigateByUrl(`${this.home}`);
  }
}
