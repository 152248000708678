import { Component, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent {
    selectedFile: File | null = null;
    selectedFileName: { name: string; blob: Blob; result: string } | null;
    preview: string = '';
    @Output() uploadFile = new EventEmitter<any>();

    selectFiles(event: any): void {
        this.selectedFileName = null;
        this.selectedFile = event.target.files[0];
        this.preview = '';
        const fileInput = event.target;
        fileInput.value = '';

        if (this.selectedFile) {
            const reader = new FileReader();
            let blob: Blob;
            reader.readAsDataURL(this.selectedFile);
            reader.onload = (e: any) => {
                blob = new Blob([reader.result], {
                    type: this.selectedFile.type,
                });
                this.preview = e.target.result;
                this.selectedFileName = {
                    name: this.selectedFile.name,
                    blob: blob,
                    result: e.target.result,
                };
                this.uploadFile.emit(this.selectedFileName);
            };
        }
    }

    removeFile(): void {
        this.selectedFileName = null;
        this.preview = '';
        this.selectedFile = null;
    }
}
