<mat-card class="personal-info-card tagus-card">
    <mat-card-content>
        <div class="row">
            <ng-container>
                <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                    <i class="flaticon-alert pe-5 fs-24 text-danger"></i>
                    <h4 class="warn-msg text-center">
                        {{ data?.type ===  'users' ? 'You have to select Users !' : 'You have to select Mails !' }}
                    </h4>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 d-flex mt-30 justify-content-center">
                    <button mat-flat-button class="tagus" type="button" (click)="close()">
                        OK
                    </button>
                </div>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>