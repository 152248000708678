<!-- for mat menu -->
<!-- <button mat-menu-item type="button" class="bg-transparent border-none position-relative"
    (click)="activityConfirmation('read')">
    <i class="ri-mail-check-line"></i> Read
</button>
<button mat-menu-item type="button" class="bg-transparent border-none position-relative"
    (click)="activityConfirmation('unread')">
    <i class="ri-mail-unread-line"></i> Unread
</button>
<button mat-menu-item type="button" class="bg-transparent border-none position-relative"
    (click)="activityConfirmation(mail_type_ids.archived)">
    <i class="ri-archive-line"></i> Archive
</button>
<button mat-menu-item type="button" class="bg-transparent border-none position-relative"
    (click)="activityConfirmation(mail_type_ids.important)">
    <i class="ri-information-line"></i> Important
</button>
<button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="share()">
    <i class="ri-share-line"></i> Share
</button>
<button mat-menu-item type="button" [matMenuTriggerFor]="move_to" class="bg-transparent border-none position-relative">
    <i class="ri-file-transfer-line"></i> Move to
</button>

<mat-menu #move_to="matMenu" class="dotMenu monthMenu" xPosition="before">
    <ng-container *ngIf="user_details && user_details?.mailAllotmentFolders?.length">
        <ng-container *ngFor="let folders of user_details?.mailAllotmentFolders">
            <button mat-menu-item type="button" class="bg-transparent border-none position-relative"
                (click)="activityConfirmation(folders.id, 'folder')">
                <i class="ri-archive-line"></i> {{folders?.name}}
            </button>
        </ng-container>
    </ng-container>
</mat-menu> -->

<!-- new ui in horizontal list -->
<button mat-icon-button matTooltip="Read" (click)="activityConfirmation('read')" *ngIf="show_read && page_type !== 'read'">
    <i class="ri-mail-check-line"></i>
</button>
<button mat-icon-button matTooltip="Unread" (click)="activityConfirmation('unread')" *ngIf="show_unread" [ngClass]="{'ms-10': page_type === 'read' }">
    <i class="ri-mail-unread-line"></i>
</button>
<button mat-icon-button matTooltip="Archive" (click)="activityConfirmation(mail_type_ids.archived)" *ngIf="show_archive">
    <i class="ri-archive-line"></i>
</button>
<button mat-icon-button matTooltip="Junk" (click)="activityConfirmation(mail_type_ids.junk)" *ngIf="show_junk">
    <i class="ri-mail-forbid-line"></i>
</button>
<button mat-icon-button matTooltip="Important" (click)="activityConfirmation(mail_type_ids.important)" *ngIf="show_important">
    <i class="ri-information-line"></i>
</button>
<!-- <button mat-icon-button matTooltip="Share" (click)="share()" *ngIf="show_shared">
    <i class="ri-share-line"></i>
</button> -->
