export const usersApis = {
    getUsersList: "v1/api/users",
    createUser: "v1/api/users",
    deleteUsers: "v1/api/users?Id=:userId",
    updateUser: "v1/api/users/update",
    getUserById: "v1/api/users/details?Id=:userId",
    uploadDocumentById: "v1/api/users/uploadDocById",
    removeDocumentById: "v1/api/users/removeUploadDocById",
    resendInvite:"v1/api/users/resentInvite?Id=:userId",
    uploadProfilePic: 'v1/api/uploadDoc'
}
