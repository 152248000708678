import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: 'input[appNumericInput]'
})
export class NumericInputDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this.el.nativeElement.value;
    const sanitizedValue = initialValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    if (initialValue !== sanitizedValue) {
      this.renderer.setProperty(this.el.nativeElement, 'value', sanitizedValue);
      event.stopPropagation();
    }
  }

}
