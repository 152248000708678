import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Editor, Toolbar } from 'ngx-editor';
import { Subject, takeUntil } from 'rxjs';
import { EmailRegex } from 'src/app/core/constants/dropdown-contants';
import { calculateSize, decodeHtml, getMailDraftEditBody, getMailReplyFormat, getSignatureWithData } from 'src/app/core/helpers/helperfunc';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { ToasterService } from 'src/app/core/services/toaster/toaster.service';
import { UniversityCommunicationService } from 'src/app/core/services/university-communication/university-communication.service';
import { UsersService } from 'src/app/core/services/users/users.service';
import { saveAs } from 'file-saver';
import { SafehtmlPipe } from 'src/app/core/pipe/safehtml.pipe';
import { EmailWarningPopupComponent } from '../../shared/email-warning-popup/email-warning-popup.component';
import { MailCommon, MailTypeIds } from 'src/app/core/constants/mail-box-const';

@Component({
  selector: 'app-create-mail',
  templateUrl: './create-mail.component.html',
  styleUrls: ['./create-mail.component.scss']
})
export class CreateMailComponent implements OnInit, OnDestroy {
  user_details: any;
  compose_email_form: FormGroup;
  editor: Editor;
  toolbar: Toolbar = [
      // default value
      ['bold', 'italic'],
      ['underline', 'strike'],
      ['code', 'blockquote'],
      ['ordered_list', 'bullet_list'],
      [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
      ['link', 'image'],
      ['text_color', 'background_color'],
      ['align_left', 'align_center', 'align_right', 'align_justify'],
      ['horizontal_rule', 'format_clear'],
  ];
  private _unsubscribeAll: Subject<any> = new Subject();
  getMailReplyFormat = getMailReplyFormat;
  reply_hidden_content: any;
  see_original_mail: boolean = false;
  is_submitted: boolean = false;
  upload_files: any[] = [];
  
  public validators = [this.startsWithAt];
  customValidators = [this.startsWithAt, this.forbiddenDomainValidator(/@crizac\.com$/)];
  public errorMessages = {
    'invalidmail': 'Invalid email'
  };
  is_cc: boolean = false;
  is_bcc: boolean = false;
  default_attachment: any;
  align_inbox_mail: any;
  mail_common = MailCommon;
  is_discard: boolean = false;
  is_subject_missing: boolean = false;
  mail_type_ids = MailTypeIds;
  paramsForMail: any;
  getMailDraftEditBody = getMailDraftEditBody;
  decodeHtml:any = decodeHtml;

  constructor(
    public dialogRef: MatDialogRef<CreateMailComponent>,
    @Inject(MAT_DIALOG_DATA) public email_data: any,
    private _userService: UsersService,
    private _authService: AuthenticationService,
    private _loaderService: LoaderService,
    private _fb: FormBuilder,
    private _toasterService: ToasterService,
    private _universitycommunicationService: UniversityCommunicationService,
    private _safe: SafehtmlPipe,
    private _dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.editor = new Editor();
    this.user_details = this._authService.getLoginUserDetails();
    this.align_inbox_mail = this.user_details?.inboxMail;
    this.initiateEmailForm();
    if (this.email_data.type === 'forward' || this.email_data.type === 'draftedit') {
      this.default_attachment = [...this.email_data?.mail_details?.attachments];
    }
    this.patchValuesOnReFw();
  }

  close(val?: any){
    if (this.compose_email_form.dirty && !this.is_discard) {
      this.openWarningPopup(this.mail_common.discard);
      return;
    }
    this.dialogRef.close(val);
  }

  initiateEmailForm() {
    this.compose_email_form = this._fb.group({
      // from: [this.user_details?.email ?? ''],
      from: [''],
      to: [''],
      cc: [''],
      bcc: [''],
      subject: [''],
      html: [''],
      attachments: [[]]
    });
    this.patchWithSignature();
  }

  patchWithSignature() {
    
    this.compose_email_form?.patchValue({
      html:this.decodeHtml(getSignatureWithData(this.email_data, this.user_details)),
    });
  }

  patchValuesOnReFw() {
    if (Object.keys(this.email_data).length) {
      let find_from_mail = this.align_inbox_mail.find(element => element.domain === this.email_data?.mail_details?.domain)?.mail;
      this.compose_email_form.get('from').setValue(find_from_mail);

      if (this.email_data.type === 'reply') {
        if (Number(this.email_data?.mail_details?.type) === this.mail_type_ids.sent) {
          let to_mails = this.email_data?.mail_details.to_mail?.replaceAll(' ', '').split(',');
          this.compose_email_form.get('to').setValue(to_mails);
        } else if (this.email_data?.mail_details?.from_mail) {
          let to_mails = this.email_data?.mail_details.from_mail?.replaceAll(' ', '').split(',');
          this.compose_email_form.get('to').setValue(to_mails);
        }
      }

      if (this.email_data.type === 'replyAll') {
        let to_mails = [];
        if (Number(this.email_data?.mail_details?.type) === this.mail_type_ids.sent) {
          let to_mails = this.email_data?.mail_details.to_mail?.replaceAll(' ', '').split(',');
          this.compose_email_form.get('to').setValue(to_mails);
        } else if (this.email_data?.mail_details?.from_mail) {
          to_mails = this.email_data?.mail_details?.from_mail?.replaceAll(' ', '').split(',');
          this.compose_email_form.get('to').setValue(to_mails);
        }
        if (this.email_data?.mail_details?.cc_mail) {
          let cc_mails = this.email_data?.mail_details?.cc_mail?.replaceAll(' ', '').split(',');
          if (cc_mails) {
            this.compose_email_form.get('cc').setValue(cc_mails);
          }
        }
        if (this.email_data?.mail_details?.bcc_mail) {
          let bcc_mails = this.email_data?.mail_details?.bcc_mail?.replaceAll(' ', '').split(',');
          if (bcc_mails) {
            this.compose_email_form.get('bcc').setValue(bcc_mails);
          }
        }
      }
      if (this.email_data.type === 'draftedit') {
        let to_mails = [];
        if (Number(this.email_data?.mail_details?.type) === this.mail_type_ids.draft) {
          let to_mails = this.email_data?.mail_details.to_mail?.replaceAll(' ', '').split(',');
          this.compose_email_form.get('to').setValue(to_mails);
        } else if (this.email_data?.mail_details?.from_mail) {
          to_mails = this.email_data?.mail_details?.from_mail?.replaceAll(' ', '').split(',');
          this.compose_email_form.get('to').setValue(to_mails);
        }
        if (this.email_data?.mail_details?.cc_mail) {
          let cc_mails = this.email_data?.mail_details?.cc_mail?.replaceAll(' ', '').split(',');
          if (cc_mails) {
            this.compose_email_form.get('cc').setValue(cc_mails);
          }
        }
        if (this.email_data?.mail_details?.bcc_mail) {
          let bcc_mails = this.email_data?.mail_details?.bcc_mail?.replaceAll(' ', '').split(',');
          if (bcc_mails) {
            this.compose_email_form.get('bcc').setValue(bcc_mails);
          }
        }
      }

      if (this.email_data?.mail_details?.subject) {
        if (this.email_data.type === 'forward') {
          let subject = this.email_data?.mail_details?.subject.includes('Fw:') ? this.email_data?.mail_details?.subject : `Fw: ${this.email_data?.mail_details?.subject}`;
          this.compose_email_form.get('subject').patchValue(subject);
        } else if (this.email_data.type === 'draftedit') {
          let subject = this.email_data?.mail_details?.subject;
          this.compose_email_form.get('subject').patchValue(subject);
        } else {
          let subject = this.email_data?.mail_details?.subject.includes('Re:') ? this.email_data?.mail_details?.subject : `Re: ${this.email_data?.mail_details?.subject}`;
          this.compose_email_form.get('subject').patchValue(subject);
        }
      }
      if(this.email_data.type === 'draftedit'){
        this.reply_hidden_content = this.getMailDraftEditBody(this.email_data);
      }else{
        this.reply_hidden_content = this.getMailReplyFormat(this.email_data);
      }

      if (this.reply_hidden_content) {
        console.log(this.reply_hidden_content);
        
        this.compose_email_form.get('html').patchValue(this.reply_hidden_content);
      }
    } else {
      this.compose_email_form.get('from').setValue(this.align_inbox_mail[0]?.mail);
    }
  }

  selectFiles(event: any): void {
    let files = event.target.files;
    if (calculateSize(files) > 25) {
      this.openWarningPopup(MailCommon.large_file);
      return;
    }
    if (files) {
      for (let file of files) {
        this.upload_files.push(file);
      }
    }
  }

  removeDoc(index: number) {
    this.upload_files.splice(index, 1);
  }

  removeDefaultAttachment(index: number) {
    this.default_attachment.splice(index, 1);
  }

  discard() {
    this.compose_email_form.reset();
    this.upload_files = [];
  }

  getPayload() {
    let to_mail = '';
    let cc_mail = '';
    let bcc_mail = '';
    let html = '';

    let form_values = this.compose_email_form.value;

    if (form_values?.to?.length) {
      form_values?.to?.forEach((element: any, index: number) => {
        if (element?.value) {
          if (EmailRegex.test(element.value)) {
            to_mail += `${element.value}${form_values?.to?.length === (index + 1) ? '' : ','}`;
          } else {
            this._toasterService.showError('ERROR', 'Invalid mail');
            return;
          }
        } else {
          if (EmailRegex.test(element)) {
            to_mail += `${element}${form_values?.to?.length === (index + 1) ? '' : ','}`;
          } else {
            this._toasterService.showError('ERROR', 'Invalid mail');
            return;
          }
        }
      })
    } else {
      to_mail = form_values?.to;
    }

    if (form_values?.cc?.length) {
      form_values?.cc?.forEach((element: any, index: number) => {
        if (element.value) {
          if (EmailRegex.test(element.value)) {
            cc_mail +=  `${element.value}${form_values?.cc?.length === (index + 1) ? '' : ','}`;
          } else {
            this._toasterService.showError('ERROR', 'Invalid mail');
            return;
          }
        } else {
          if (EmailRegex.test(element)) {
            cc_mail += `${element}${form_values?.cc?.length === (index + 1) ? '' : ','}`;
          } else {
            this._toasterService.showError('ERROR', 'Invalid mail');
            return;
          }
        }
      })
    } else {
      cc_mail = form_values?.cc;
    }

    if (form_values?.bcc?.length) {
      form_values?.bcc?.forEach((element: any, index: number) => {
        if (element.value) {
          if (EmailRegex.test(element.value)) {
            bcc_mail += `${element.value}${form_values?.bcc?.length === (index + 1) ? '' : ','}`;
          } else {
            this._toasterService.showError('ERROR', 'Invalid mail');
            return;
          }
        } else {
          if (EmailRegex.test(element)) {
            bcc_mail += `${element}${form_values?.bcc?.length === (index + 1) ? '' : ','}`;
          } else {
            this._toasterService.showError('ERROR', 'Invalid mail');
            return;
          }
        }
      })
    } else {
      bcc_mail = form_values?.bcc;
    }

    html = this.compose_email_form.value.html;

    let formData = new FormData();
    formData.append('to', to_mail);
    formData.append('cc', cc_mail);
    formData.append('bcc', bcc_mail);
    formData.append('html', html);
    formData.append('subject', form_values?.subject);

    if (this.email_data.type === 'forward' || this.email_data.type === 'draftedit') { 
      formData.append('defaultAttachment', JSON.stringify(this.default_attachment));
    }
    
    this.upload_files.forEach(element => {
      formData.append('attachments', element);
    })

    return formData;
  }

  sendMail() {
    this.is_submitted = true;

    let params = '?';
    if (this.compose_email_form.value.from) {
      let find_domain = this.align_inbox_mail.find(element => element.mail === this.compose_email_form.value.from)?.domain;
      params += `name=${this.user_details?.name}&from=${this.compose_email_form.value.from}&domain=${find_domain}`
    }
    this.paramsForMail = params;
    let formData = this.getPayload();

    if (this.compose_email_form.invalid) {
      return;
    }

    if (!this.compose_email_form.value.to) {
      this._toasterService.showError('ERROR', 'This message must have at least one recipient.')
      return;
    }

    if (!this.compose_email_form.value.subject && !this.is_subject_missing) {
      this.openWarningPopup(this.mail_common.missing_subject);
      return;
    }

    this._loaderService.showLoader();
    this._universitycommunicationService.sendMail(params, formData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res) {
          this.is_discard = true;
          this._toasterService.showSuccess('SUCCESS', 'Email sent successfully');
          this.close(true);
          this._loaderService.hideLoader();
        }
      }, (err) => {
        this._loaderService.hideLoader();
      })
  }

  private startsWithAt(control: FormControl) {
    if (!EmailRegex.test(control.value)) {
        return {
            'invalidmail': true
        };
    }

    return null;
  }

  showCC() {
    this.is_cc = !this.is_cc;
  }

  showBCC() {
    this.is_bcc = !this.is_bcc;
  }

  viewAttachment(file: any) {
    saveAs(file.filePath, file.fileName);
  }

  openWarningPopup(type: string) {
    let params = '?';
    if (this.compose_email_form.value.from) {
      let find_domain = this.align_inbox_mail.find(element => element.mail === this.compose_email_form.value.from)?.domain;
      params += `name=${this.user_details?.name}&from=${this.compose_email_form.value.from}&domain=${find_domain}`
    }

    const dialogRef = this._dialog.open(EmailWarningPopupComponent, {
      width: '25%',
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '100ms',
      data: {
        formData:this.getPayload(),
        params: params,
        type: type
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result === this.mail_common.discard) {
          this.is_discard = true;
          this._universitycommunicationService.checkMails.next(true);
          this.close();
        } else if (result === this.mail_common.send) {
          this.is_discard = true;
          this.is_subject_missing = true;
          this.sendMail();
        } else {
          dialogRef.close();
        }
      }
    });
  }
  
  forbiddenDomainValidator(forbiddenDomain: RegExp) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = forbiddenDomain.test(control.value);
      return forbidden ? null:{
        'invalidmail': true
      }
      // { 'forbiddenDomain': { value: control.value } } : null;
    };
  }
  ngOnDestroy(): void {
    this.editor.destroy();
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
