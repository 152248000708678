import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { CustomizerSettingsService } from 'src/app/components/customizer-settings/customizer-settings.service';
import { MailCommon, MailSidebar } from 'src/app/core/constants/mail-box-const';
import { permissionEntity } from 'src/app/core/constants/permission';
import { urlRoutes } from 'src/app/core/constants/url-route.constant';
import { random3DigitId } from 'src/app/core/helpers/helperfunc';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { LocalStorageService } from 'src/app/core/services/localStorage/localStorage.service';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { UniversityCommunicationService } from 'src/app/core/services/university-communication/university-communication.service';
import { CreateMailComponent } from 'src/app/views/university-communications/mail-box/create-mail/create-mail.component';

@Component({
  selector: 'app-mail-sidebar',
  templateUrl: './mail-sidebar.component.html',
  styleUrls: ['./mail-sidebar.component.scss']
})
export class MailSidebarComponent implements OnInit, OnChanges, OnDestroy {
  mail_sidebar = MailSidebar;
  @Input() dashboard_counts: any;
  @Output() trigger_menu_change: EventEmitter<any> = new EventEmitter();
  @Output() trigger_folder: EventEmitter<any> = new EventEmitter();
  
  public routerUrl = urlRoutes;
  selected_menu: any;
  permissions_entity = permissionEntity;
  show_folder_input: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject();
  user_details: any;
  panelOpenState = false;
  domain_sidebar: any;
  selected_domain: any;
  selected_domain_index: number;

  folder_name: string = '';
  folder_action_type: string = '';
  selected_folder_id: any;
  selected_folder_domain: string = '';

  constructor(
    public themeService: CustomizerSettingsService,
    private _navigationService: NavigationService,
    public dialog: MatDialog,
    private _loaderService: LoaderService,
    private _universitycommunicationService: UniversityCommunicationService,
    private _authService: AuthenticationService,
    private _localStorageService: LocalStorageService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dashboard_counts']?.currentValue) {
      this.dashboard_counts = changes['dashboard_counts']?.currentValue;
      if (this.dashboard_counts) {
        this.domain_sidebar = this.dashboard_counts.map((element: any, index: number) => {
          return {
            domain: element.domain,
            mail: element.mail,
            totalFolderCount: element?.totalFolderCount,
            sub_menus: this.mail_sidebar.map(items => {
              return { 
                id: items.id,
                menu: items.menu,
                icon: items.icon,
                count: element[items.count_label]
              }
            })
          }
        })
      }
      let stored_domain = JSON.parse(localStorage.getItem(MailCommon.selected_domain))
      if (stored_domain) {
        this.selected_domain = stored_domain;
        this.selected_domain_index = this.dashboard_counts?.findIndex(ele => ele.mail === this.selected_domain?.mail);
      }
    }
  }

  ngOnInit(): void {
    this.user_details = this._authService.getLoginUserDetails();
    this.selected_menu = this.mail_sidebar[0];
  }

  loadMail(menu: any, sidebar: any) {
    this.selected_domain = {domain: sidebar?.domain, mail: sidebar?.mail};
    this._localStorageService.setKey(MailCommon.selected_domain, JSON.stringify({...this.selected_domain}));
    this.selected_menu = menu;
    this.trigger_menu_change.emit(menu);
  }

  navigateTo(path: string) {
    let url = `${urlRoutes.mainLayout}/${urlRoutes.university_communications}/${urlRoutes.mail_box}/${path}`;
    this._navigationService.routeToPath(url)
  }

  toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
  }

  public composeMail(): void {
    const dialogRef = this.dialog.open(CreateMailComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        enterAnimationDuration: '300ms',
        exitAnimationDuration: '100ms',
        data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
       
      }
    });
  }

  createFolder(domain) {
    this.folder_action_type = 'add';
    this.selected_folder_domain = domain;
    this.show_folder_input = true;
  }

  editFolder(index: number, domain: string, folder: any) {
    this.selected_folder_domain = domain;
    this.folder_action_type = 'edit';
    this.show_folder_input = true;
    this.selected_folder_id = folder?.id;
    this.folder_name = folder?.name
  }

  submitFolder(domain: any) {
    let payload = this.user_details?.mailAllotmentFolders;
    let find_folder_index = payload?.findIndex(folders => folders?.id === this.selected_folder_id);

    if (this.folder_action_type === 'edit') {
      if (payload?.length) {
        payload[find_folder_index].name = this.folder_name;
      }
    } else {
      if (payload?.length) {
        payload.push(
          {
            id: String(random3DigitId()),
            name: this.folder_name,
            domain: domain,
            status: true
          }
        )
      } else {
        payload = [
            {
              id: String(random3DigitId()),
              name: this.folder_name,
              domain: domain,
              status: true
            }
          ]
      }
    }

    this._loaderService.showLoader();
    this._universitycommunicationService.createFolder(this.user_details.id, {mailAllotmentFolders: [...payload]})
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((res: any) => {
      if (res && res?.statusCode === 200) {
        this.folder_name = '';
        this.show_folder_input = false;
        this.user_details['mailAllotmentFolders'] = res?.data?.mailAllotmentFolders;
        this._localStorageService.setKey('user_details', JSON.stringify(this.user_details));
        if (this.selected_domain?.domain !== domain) {
          this._localStorageService.setKey(MailCommon.selected_domain, domain);
          this.trigger_menu_change.emit(this.selected_menu);
        } else {
          this.trigger_folder.emit(true)
        }
      }
      this._loaderService.hideLoader();
    }, (err) => {
      this._loaderService.hideLoader();
    })
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
